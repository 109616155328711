.tablecell-agenda
	display: block
	width: 100%
	height: 100%
	&.bank-day
		background-color: #cccccc55
	&.notworking-cell
		//background-color: #cccccc55
		//background-color: white
		//background-color: #e7e7e7
		background-image: linear-gradient(140deg, #e0e0e099 25%, #e2e2e24d 25%, #e2e2e24d 50%, #e0e0e099 50%, #e0e0e099 75%, #e2e2e24d 75%, #e2e2e24d 100%) !important
		background-size: 60px 50px !important

.select-state-item
	display: flex
	align-items: center
	svg
		font-size: 18px
		margin-right: 8px

.missed-appointment > div
	//background-color: #FF0000 !important
	background-image: linear-gradient(140deg, #FF000055 25%, #FF000033 25%, #FF000033 50%, #FF000055 50%, #FF000055 75%, #FF000033 75%, #FF000033 100%) !important
	background-size: 62.23px 52.22px !important
	border: 1px solid red !important
	opacity: .5

.missed-appointment
	&.MISSED > div
		background-color: rgba(159, 39, 87, .5) !important
	&.CANCELLED > div
		background-color: rgba(214, 156, 100, .5) !important

.agenda-appointment-tooltip-icons
	a
		color: black !important

.schedule-calendar
	position: absolute
	display: block
	max-height: calc(100vh - 110px)
	height: calc(100vh - 126px)
	width: 98%
	overflow: auto
	//overflow-y: show
	background-color: rgb(248, 248, 251)

	// [class*="VerticalAppointment-title"]
	// 	color: white
	// 	mix-blend-mode: difference

	.current-hour-cell
		background-color: #ffffff

	.makeStyles-background-18
		background-color: rgb(248, 248, 251)
	.makeStyles-ordinaryLeftPanelBorder-10
		background-color: rgb(248, 248, 251)

	//tr .MuiTableCell-root
	//	border-bottom: none//1px solid #eee
	//td[class*="makeStyles-cell"]
	//	border-right: 1px solid #eee

	div[class*="makeStyles-background"]
		//background-color: var(--primary-light) !important
		border-radius: 5px
	div[class*="makeStyles-stickyElement"]
		//background-color: var(--primary-light) !important
		border-radius: 5px
	[class*="Cell-highlightedText"]
		color: white
		background-color: var(--primary-light)

	p[class*="Cell-highlightedText"]
		//border-radius: 5px 10px 0px 0px
		//font-size: 10px !important

	div[class*="Cell-dayOfMonth-"]
		font-size: 16px !important

	p[class*="Cell-dayOfWeek-"]
		text-transform: uppercase !important

	div[class*="makeStyles-appointment"]
		left: 5px

	div[class*="makeStyles-text-"]
		font-size: 10px

.agenda-row-userlabo
	height: 42px
	max-height: 42px
	margin-bottom: 6px
	.ant-select-multiple
		border: 1px solid #eee
		border-radius: 5px
	input
		height: 42px
		max-height: 42px
		border-radius: 10px

.agenda-configuration
	width: 100%
	margin-bottom: 6px
	.row, .col-8, .col-2
		margin: 0px
		padding: 1px
	button.btn
		padding-left: 6px
		padding-right: 6px

.agenda-leftbar
	max-width: 300px !important

.calendar-picker
	width: 100%
	display: block
	//border-radius: 10px
	//border: 1px solid var(--primary-dark)
	background-color: rgb(248, 248, 251)
	font-size: 14px

	.ant-picker-cell, .ant-picker-body
		background-color: rgb(248, 248, 251)

	.ant-picker-calendar-header
		background-color: var(--primary-dark)
		border-radius: 5px
		background-color: rgb(248, 248, 251)
	//border: 1px solid var(--primary-dark)

	//.ant-select-selector
	//	width: 80px
	//	border-radius: 10px

	.ant-picker-cell-inner, .ant-picker-calendar-date
		font-size: 14px
		font-weight: bold
	.ant-select-selection-item
		width: 80px
		flex-grow: 1
		border-radius: 10px
		background-color: rgb(248, 248, 251)

	.ant-picker-cell-selected
		background-color: var(--primary)
		border-radius: 5px

	.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner
		background-color: var(--primary)
		border: 0px

	.ant-picker-calendar-date-today
		//background-color: #159e83
		//color: white
		font-weight: bold
		border-bottom: 2px solid var(--primary)
		&::before
			border: 0px !important

.calendar-filter
	width: 100%
	display: block
	//border: 1px solid #aaa
	//border-radius: 5px
	margin-top: 6px
	.filter
		margin-top: 6px
		margin-bottom: 4px
		border-radius: 5px
		width: 100%
		height: 30px
		display: flex
		justify-content: flex-start
		align-items: center
		padding: 6px
		//border: 1px solid #aaa
		//border-radius: 5px

		.filter-switch
			display: flex
			align-items: center
			input[type=checkbox]
				width: 24px
				height: 24px
		.filter-color
			background-color: red
			border-radius: 50%
			width: 20px
			height: 20px
			margin-left: 4px
			&.bank-day
				background-color: blue
			&.birthdays
				background-color: yellow
			&.done
				background-color: #33ff88
			&.missed
				background-color: rgb(159, 39, 87)
			&.cancelled
				background-color: rgb(214, 156, 100)

		.filter-label
			margin-left: 6px

.ant-dropdown
	z-index: 1700 !important

.fullscreen-agenda-hideleftbar
	#leftBar-fullScreen
		display: none !important
	#main-body
		width: 100% !important
		margin: 0px
	.locked~#main-body
		margin: 0px !important
	.navbar-nav
		display: none !important
	.navbar
		display: none !important
