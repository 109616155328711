.previous-search {
    width: calc(100% - 20px);
    position: absolute;
    z-index: 10;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 2px;

    li{
        font-size:14px;
        list-style-type: none;
        padding: 8px 0 8px 13px;
    }
    
    li:hover {
        cursor: pointer;
        background-color: var(--primary);
        color:#fff;
        font-weight: 700;
    }

    span {
        padding: 15px 2px 10px 15px;
        height: 20px;
        display: flex;
        align-items: center;
        color:#d9d9d9;
        hr {
            border-top: 1px solid #d9d9d9;
            width: 80%;
        }
    }
}

.bl-table:hover {
    cursor: pointer;
}

.badge-bl-secondary {
	color: #008299;
	background-color: rgba(0, 184, 217, 0.4);
    font-size: 14px;
    font-weight: 700;
}

.badge-bl-primary {
	color: #0E816A;
	background-color: rgba(24, 188, 156, 0.4);
    font-size: 14px;
    font-weight: 700;
}