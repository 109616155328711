@import "../../assets/scss/variables";

.coverage-demand-amount {
  background-color: $light;
  color: $dark;
  padding: 5px 15px;
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 4px;

  .title {
    font-size: 11px;
    font-weight: 700;
  }

  .price {
    font-size: 14px;
    font-weight: 700;
  }
}
