.document-email-selector-switch-grid {
	display: grid;
	grid-template-columns: auto repeat(2, 1fr) auto;
	column-gap: 1rem;	
	row-gap: .5rem;
	align-items: center;
	grid-template-areas:
		"switch label label toggle"		
		"input input input confirm"
		"error error error error";

	.area-switch {
		grid-area: switch;
	}

	.area-label {
		grid-area: label;
	}

	.area-error {
		grid-area: error;
	}

	.area-toggle {
		grid-area: toggle;
	}

	.area-confirm {
		grid-area: confirm;
	}

	.area-input {
		grid-area: input;
	}
}
