.layout {
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "header header""sidebar content";

  &__header {
    grid-area: header;

    &--hide {
      display: none;
    }
  }

  &__sidebar {
    grid-area: sidebar;
    overflow: auto;

    &--hide {
      display: none;
    }
  }

  &__content {
    overflow: auto;
    grid-area: content;
    position: relative;
    scrollbar-width: thin;
    background: #2626260a;
    padding: 1rem;

    &--hide {
      display: none;
    }
  }
}


// @media (min-width: 576px) {

// }

// @media (min-width: 768px) {

// }

// @media (min-width: 992px) {

// }

// @media (min-width: 1200px) {

// }