@import '../variables'

.modal-documentviewer
	.modal-body
		.preview-container
			width: 100%
			height: 100%
		img
			width: 90%

		height: 80% !important
		max-height: 80% !important

.modal-documentuploader-droppreview
	display: flex
	width: 100%
	height: 180px
	.modal-documentuploader-dropzone
		width: 80%
		margin: 10px
	.modal-documentuploader-preview
		width: 20%
		margin: 4px
		border-width: 2px
		border-radius: 2px
		border-color: $light
		border-style: dashed
		overflow: hidden
