.administration-card {
	.ant-card-head {
		.ant-tabs-ink-bar {
			height: 4px;
		}
	}
	.ant-card-body {
		height: calc(100% - 58px) !important;
		overflow-y: auto !important;
	}
}
