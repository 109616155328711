@import "../../../assets/scss/variables.scss";

.coverage-panel-line {
  .line-container {
    border-bottom: 1px dashed $disabled;
    display: flex;
    flex-direction: row;
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }

  .title-container {
    flex: 1;
  }

  .value-container {
    flex: 1.5;
    text-align: right;
    font-weight: 700;
  }
}
