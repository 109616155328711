.administration-collapse {
	.ant-collapse-header {
		color: var(--primary) !important;
		font-weight: bold !important;
		font-size: 1rem !important;
		display: flex !important;
		align-items: center !important;
		line-height: normal !important;

		.ant-collapse-arrow > svg {
			width: 16px !important;
			height: 16px !important;
		}
	}
}
