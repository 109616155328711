.insurance-header {
	width: 30%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.insurance-header-text {
	font-weight: 600;
	color: black;
}

.sale-item,
.devis-button {
	background-color: $light;
	transition: all 0.25s;
	cursor: pointer;

	&:hover:not(.nohover) {
		background-color: var(--primary-light);
		color: $white;
	}

	&.selected:not(.nohover) {
		background-color: var(--primary);
		color: $white;
	}

	&:disabled {
		cursor: not-allowed;
		background-color: $red-light;
		color: inherit;
	}

	.icon-img-container {
		width: 20px;
		height: 20px;
		transition: all 0.2s linear;
		&.hide {
			width: 0px;
			height: 0px;
			font-size: 0px;
		}
	}
}

.bg-row-missingpayment {
	color: white;
	background-color: rgb(252, 119, 119);
	td.ant-table-column-sort {
		background-color: rgb(230, 107, 107);
		// color: #000000 !important
	}
	td > button {
		background-color: rgb(204, 93, 93);
		color: white;
		border: rgb(168, 74, 74);
		&:hover {
			color: white;
			background-color: rgb(201, 92, 92);
			border: rgb(160, 71, 71);
		}
	}
	&:hover td {
		color: white !important;
		background-color: rgb(230, 107, 107) !important;
	}
}

.overflow-hidden {
	overflow-y: hidden;
}

.credit-items {
	.credit-item {
		width: 168px;
		height: 168px;
		margin: 2px;
	}
}

.devis-button {
	box-sizing: border-box;
	flex-basis: 47%;
}

.new-updated-products > * {
	flex-basis: 30%;
}

.empty {
	color: var(--primary);
}

.left {
	flex-basis: 40%;
}

.cart {
	position: relative;
	flex-basis: 50%;

	.payment-options {
		display: flex;
		flex-wrap: wrap;
		button {
			width: 190px;
			flex-grow: 1;
			padding: 10px;
			background-color: white;
			svg {
				margin-right: 6px;
			}
		}
	}

	.payment-action button:disabled {
		cursor: not-allowed;
	}
}

.cart-item-column {
	flex-basis: 24%;
}

.cart-item-ln-delete {
	width: 42px;
	//background-color: #ff563013;
	height: 84px;
	display: flex;
	margin-left: 4px;
	margin-right: 6px;
	align-content: center;
	justify-content: center;
	transition: all 0.2s linear;
	cursor: pointer;
	align-items: center;
	svg {
		//color: #ff563088;
		font-size: 20px;
	}
	border-radius: 5px;
	&:hover,
	&:active {
		background-color: #ff563044;
		svg {
			color: #ff5630;
		}
	}
}

.cart-items-ln {
	display: block;
	width: 100%;
	border-radius: 5px;
	padding: 4px;
	box-shadow: #00000022 1px 1px 5px 0px;
	margin: 4px;
	border: 1px solid #00000022;

	&.GAUCHE {
		border: 1px solid var(--info);
		border-left: 8px solid var(--info);
	}

	&.DROITE {
		border: 1px solid #ff563099;
		border-left: 8px solid #ff563099;
	}

	.cart-item-product {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;

		.cart-item-selectEar {
			display: none;
			position: absolute;
			background-color: #eeeeeebc;
			width: 100%;
			height: 96px;
			z-index: 200;
			text-align: center;
			line-height: 96px;
			vertical-align: middle;
		}

		.flleft {
			position: relative;
			float: left;
		}
		.flright {
			position: relative;
			float: right;
		}
		> div {
			border-radius: 5px;
			width: 50%;
			display: block;
			padding: 4px;
		}
		.cart-item-product-quantity {
			input {
				width: 56px;
				padding: 5px;
				height: 32px;
				margin-right: 6px;
				text-align: center;
			}
			button {
				border-radius: 5px;
			}
		}
		.cart-item-product-name {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: bold;
		}
		.cart-item-product-size-name {
			overflow: hidden;
			font-size: 12px;
			white-space: nowrap;
		}
		.cart-item-product-serial {
			overflow: hidden;
			font-family: monospace;
			white-space: nowrap;
			font-size: 14px;
		}
		.cart-item-product-price {
			border-bottom: 1px solid #dddddd;
			border-radius: 5px;

			&.lighter {
				opacity: 0.3;
			}

			.label {
				float: left;
				text-align: left;
				text-transform: lowercase;
				font-style: italic;
				font-size: 14px;
				//background-color: red;
				width: 40%;
				max-width: 128px;
			}
			text-align: right;
			//background-color: greenyellow;
			overflow: hidden;
			white-space: nowrap;
			font-size: 14px;

			.cart-item-pricechange {
				//background-color: red;
				display: block;
				float: right;
				width: 60%;
				input {
					margin-right: 8px;
					//background-color: yellow;
					width: 70%;
					border: 2px solid #ffbb00;
				}
			}
		}
	}
}

.cart-item {
	.plus-quantity {
		background-color: var(--primary);
	}

	.minus-quantity {
		background-color: $red;
	}

	.plus-quantity,
	.minus-quantity {
		overflow: hidden;
		font-size: 1.75em;
		width: 30px;
		height: 30px;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		transition: transform 0.25s;

		&:hover {
			transform: scale(1.1);
		}

		&:disabled {
			opacity: 0.65;
			transform: none;
			cursor: not-allowed;
		}
	}

	.delete-item-button {
		font-size: 1.75em;
		cursor: pointer;
		transition: transform 0.25s;
		color: $red;

		&:hover {
			transform: scale(1.1);
		}
	}

	input {
		-moz-appearance: textfield;
		border: 2px solid $light;
		border-radius: 5px;
		transition: border 0.25s;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&:focus {
			outline: none;
			border: 2px solid $dark;
		}
	}
}

.item-separator {
	height: 1px;
	background-color: $light;
	width: 50%;
}

.total-container {
	width: 100%;
	flex-wrap: wrap;
}
.cart-summary {
	//border-bottom: 1px solid var(--primary);
	&.amount-neg .total {
		color: inherit;
	}
	margin: 0px;
	width: 55%;
	font-size: 16px;
	margin-top: 6px;
	border-radius: 5px;
	b {
		margin: 0px;
	}
	.total {
		padding-left: 24px;
		padding-right: 52px;
		font-weight: bold;
		//border-style: solid;
		//border-radius: 5px;
		//border-width: 0px;
		//border-color: var(--primary);
		//font-size: 1.5em;
		color: var(--primary-dark);
	}
}

.cancel-new-payment {
	background-color: $red;
	overflow: hidden;
	font-size: 1.25em;
	width: 30px;
	height: 30px;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;
}

.remove-payment {
	cursor: pointer;
	color: $red;
	font-size: 1.75em;
}

.new-payment-info {
	overflow: hidden;
	margin: 0;
	max-height: 0;

	&.show {
		max-height: 300px;
	}

	.add-new-payment {
		overflow: hidden;
		font-size: 1.25em;
		width: 30px;
		height: 30px;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		border: none;
		background-color: var(--primary);
	}
}

.div-payment-modal {
	.add-payment {
		cursor: pointer;

		&:hover {
			.add-payment-icon {
				background-color: var(--primary);
				color: $white;
			}

			.add-payment-text {
				color: var(--primary);
			}
		}

		.add-payment-icon {
			width: 30px;
			height: 30px;
			font-size: 1.25em;
			display: flex;
			justify-content: center;
			align-items: center;
			color: var(--primary);
			border: solid var(--primary) 1px;
			transition: background-color 0.25s, color 0.25s;
		}

		.add-payment-text {
			color: $text-dark;
			transition: color 0.25s;
		}
	}

	.amount-remaining {
		background-color: $blue;
		color: $white;
		font-weight: bold;
		padding: 5px 30px;
		border-radius: 50px;
	}

	.negative-amount {
		background-color: $red;
	}

	.right-amount {
		background-color: var(--primary);
	}

	input {
		-moz-appearance: textfield;
		border: 2px solid $light;
		border-radius: 5px;
		transition: border 0.25s;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&:focus {
			outline: none;
			border: 2px solid $dark;
		}
	}
}

.add-product {
	width: 38px;
	height: 38px;
	overflow: hidden;
	font-size: 1.4em;
	color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.25s;
	background-color: var(--primary);

	&:hover {
		cursor: pointer;
		background-color: var(--primary-dark);
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.65;
		background-color: var(--primary);
	}
}

.modal-total-price {
	background-color: var(--primary);
	color: $white;
	border-radius: 20px;
	padding: 5px 20px;
	font-weight: bold;
}

.previous-sale-item-list {
	max-height: 450px;
	overflow-y: auto;
}

.download-invoice {
	border: solid 1px var(--primary);
	color: var(--primary);
	padding: 5px 20px;
	border-radius: 50px;

	&:hover {
		color: $white;
		background-color: var(--primary);
	}
}

.block-row {
	display: block;
	margin: 8px;
}

.sale-item {
	height: 142px;
	margin: 4px;
	width: 200px;
	overflow: hidden;
	border: 0px;
	border-left: 3px solid blue;
	transition: all 0.2s linear;
	flex-grow: 1;
	.icon-img-container img,
	svg {
		height: 20px;
		width: 20px;
	}
	.product-name {
		width: 100%;
		font-size: 16px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	.product-price {
		font-weight: bold;
		font-size: 18px;
	}
	.product-serial {
		font-family: monospace;
		font-size: 14px;
	}
}

.product-container {
	width: 100%;
}
.sale-item-container {
	width: 100%;
	display: block;
	span.container-text {
		display: block;
	}
	.highlight-search {
		background-color: yellow;
		padding: 0px;
		margin: 0px;
		border-radius: 5px;
	}
}

.sale-item-br {
	border-left: 2px solid blue;
}

.discount-modal {
	max-width: 85% !important;
}

.modal-div {
	.modal-content {
		min-height: 700px;
	}
}

.modal-custom-header {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.modal-amountleft {
		font-weight: bold;
		min-width: 240px;
		text-align: right;
		.label {
			font-weight: normal;
			float: left;
		}
	}
}

.btn-outline-primary.active:hover {
	filter: contrast(160%);
}
button.modal-div-footer-btn {
	height: 60px;
	padding: 10px;
	flex-grow: 1;
}

.div-container-new {
	.btn-max-left-amount {
		top: 12px;
		right: 32px;
		color: var(--primary);
		position: absolute;
		cursor: pointer;
		font-size: 14px;
	}

	h5 {
		border-bottom: 1px solid #000;
		margin-bottom: 16px;
	}
	margin-bottom: 21px;

	.form-row {
		display: flex;
		align-items: center;
		margin-bottom: 2px;
		padding: 4px;

		&.flag-delete {
			background-color: #db531d99;
			opacity: 0.5;
			border-radius: 5px;
			input,
			button {
				opacity: 0.5;
			}
		}
		&.flag-update {
			background-color: #9400d922;
			border-radius: 5px;
		}
		&.flag-new {
			background-color: var(--info);
			border-radius: 5px;
		}
	}
	.ant-switch {
		height: 40px;
		border-radius: 5px;
		width: 100%;
		&.ant-switch-checked {
			background-color: var(--primary);
		}
	}
	.ant-switch-handle {
		border-radius: 5px;
		height: 34px;
		&::before {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: #fff;
			border-radius: 5px;
			-webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
			box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			content: "";
		}
	}
	.ant-switch-inner {
		font-size: 14px;
	}
	button.add {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		background-color: #ffffff;
		color: var(--info);
		border: none;
		border-radius: 5px;
		transition: all 0.2s linear;
		svg {
			font-size: 36px;
		}
		&:hover,
		&:active {
			background-color: var(--info);
		}
	}
	button.remove {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		background-color: #ffffff;
		border: none;
		border-radius: 5px;
		transition: all 0.2s linear;
		color: #db531d99;
		svg {
			font-size: 36px;
		}
		&:hover,
		&:active {
			background-color: #db531d22;
			color: #db531d;
		}
	}
}

.vente-patientinfo-product {
	background-color: #e2ccff88;
	color: #4816a2;
	border-radius: 5px;
	padding: 5px;
	position: relative;
	margin-bottom: 4px;

	&.DROITE {
		background-color: #ff563055;
		color: #551b0e;
	}
	&.GAUCHE {
		background-color: var(--info);
		color: #00505e;
	}

	&.alreadyInCart {
		opacity: 0.5;
		background-image: linear-gradient(
			45deg,
			#00000022 25%,
			#00000055 25%,
			#00000055 50%,
			#00000022 50%,
			#00000022 75%,
			#00000055 75%,
			#00000055 100%
		);
		background-size: 56.57px 56.57px;
	}
	//background-color: #ff000055;
	//color: #aa0000;
	.pi-productname {
		display: inline;
		font-weight: bold;
	}
	.pi-productserial {
		font-family: monospace;
		margin-left: 0px;
	}
}

@keyframes slide-down {
	0% {
		height: 0%;
	}
	100% {
		height: 100%;
	}
}

.earSelectionContainer {
	width: 100%;
	height: 0%;
	transition: all 0.2s linear;
	display: flex;
	align-items: center;
	overflow: hidden;
	&.show {
		height: 70%;
	}

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
		height: 100%;
		font-size: 36px;
		font-weight: 700;
	}

	.earSelectionContainer-right {
		background-color: #ff563055;
		color: #ff5630;
		&:hover:not(.disabled) {
			background-color: #ff563099;
			color: #ffffff;
		}
		&.disabled {
			opacity: 0.5;
			background-color: #c4c4c4;
			color: #9a9a9a;
			cursor: not-allowed;
		}
	}
	.earSelectionContainer-left {
		background-color: var(--info);
		color: var(--color);
		&:hover:not(.disabled) {
			background-color: var(--info);
			color: #ffffff;
		}
		&.disabled {
			background-color: #c4c4c4;
			color: #9a9a9a;
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
}

.modal-patientinfo {
	.modal-content {
		//background-color: #e2ccff;
		box-shadow: none;
	}
	//position: relative;
	//padding: 0.875rem;
	//margin-bottom: 1rem;
	//border: 1px solid transparent;
	//border-radius: 0.25rem;
	//color: #4816a2;
	//background-color: #e2ccff;
	//border-color: #d6b8ff;
}

.crd-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 5px;
	margin-bottom: 6px;
	padding: 12px;
	background-color: #eff2f7;
	border: 1px solid #dbdfe6;
	.crd-number {
		font-weight: bold;
	}
	.crd-date {
		font-size: 14px;
	}
	.crd-price {
		font-size: 20px;
	}
}

.sale-switch {
	.ant-switch {
		height: 46px;
		border-radius: 5px;
		width: 70%;
		&.ant-switch-checked {
			background-color: var(--primary);
		}
	}
	.ant-switch-handle {
		border-radius: 5px;
		margin-top: 3px;
		height: 36px;
		&::before {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: #fff;
			border-radius: 5px;
			-webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
			box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			content: "";
		}
	}
	.ant-switch-inner {
		font-size: 14px;
	}
}
