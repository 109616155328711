.stock-fa-square
    --fa-primary-opacity: 0
    --fa-secondary-opacity: .6
    &.fa-check-square
        --fa-secondary-opacity: 1
        color: #1ca31c
    &.fa-minus-square
        --fa-secondary-opacity: 1
        color: #ce822b
    &.facheckbox-disabled
        --fa-primary-opacity: .3
        --fa-secondary-opacity: .5
