.template-form {
	height: 100%;

	&__content {
		margin-bottom: 1.25rem; // FIXME: Card mb to 0

		form {
			display: flex;
			flex-direction: column;

			textarea {
				resize: none;
			}

			input,
			textarea {
				color: #616a77 !important;
			}
		}

		& > *:not(:first-child),
		form > label:not(:first-child) {
			margin-top: 2em;
		}

		.editor {
			height: 60vh;
			flex: unset;
		}
	}
}
