.sms-template-editor-wrapper {
	height: 15rem;
}

.sms-template-preview-bubble {
	white-space: pre-wrap;
	color: white;
	background-color: #0b93f6;

	padding: 10px 20px;
	border-radius: 25px;
	margin-bottom: 12px;
	line-height: 24px;
	position: relative;

	&:before,
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		height: 25px;
	}

	&:before {
		right: -7px;
		width: 20px;
		background-color: #0b93f6;
		border-bottom-left-radius: 16px 14px;
	}

	&:after {
		right: -26px;
		width: 26px;
		background-color: white;
		border-bottom-left-radius: 10px;
	}
}
