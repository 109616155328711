.edition-select {
	&__custom-option {
		$margin: 12px;

		display: flex;
		align-items: center;
		justify-content: space-between;
		color: var(--gray-dark);
		cursor: pointer;
		font-weight: 600;
		font-size: 1.1rem;
		position: relative;

		&::before {
			content: "";
			width: calc(100% - (#{$margin} * 2));
			height: 100%;
			position: absolute;
			top: 0;
			left: $margin;
			opacity: 0.2;
			border-radius: 4px;
			transition: background-color 200ms ease-in-out;
		}

		& > *:not(:first-child) {
			padding: 10px;
		}

		&-label {
			display: flex;
			flex-direction: column;
		}

		&-description {
			display: inline-block;
			flex: 1;
			width: 400px;
			padding-left: 2em;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			font-weight: 100;
			color: #5f6f85;
			font-size: 0.9rem;
		}

		&-badges {
			display: flex;
			justify-content: center;
			align-items: center;
			& > *:not(:last-child) {
				margin-right: 10px;
			}
		}

		&:hover {
			&::before {
				background-color: #c2c2c2;
			}
		}
	}
}
