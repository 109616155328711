.pill-laboratory
{
	display: inline-block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	margin-right: 2px;
}

.laboratory-label
{
	display: flex;
	align-items: center;
}

.not-available {
	td {
		color: var(--danger-dark) !important;
		font-weight: bold;
	}
}