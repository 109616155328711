.quote-extra-fields {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto 1fr;
	gap: 0.5rem 0.5rem;
	grid-template-areas:
		"step-trial step-billing"
		"offer-1 offer-2"
		"security-deposit-amount security-deposit-amount";

	> fieldset {
		display: contents;
	}

	> .step-trial {
		grid-area: step-trial;
	}
	> .step-billing {
		grid-area: step-billing;
	}
	> .offer-1\.1 {
		grid-area: offer-1;
	}
	> .offer-1\.2 {
		grid-area: offer-2;
	}
	> .security-deposit-amount {
		grid-area: security-deposit-amount;
	}
}
