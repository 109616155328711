.is-transferred {
	// background-color: #dddddd !important;
	// opacity: 0.5;
	position: relative;

	td {
		color: var(--danger-dark) !important;
	}

	// &::after {
	// 	content: "Ce produit est dans un transfert";
	// 	background-color: rgba(221, 221, 221, 0.801);
	// 	background-size: contain;
	// 	background-repeat: no-repeat;
		
	// 	width: 100%;
	// 	height: 100%;
	// 	position: absolute;
	// 	top: 0px;
	// 	left: 0px;
	// 	font-size: 24px;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	color: #000;
	// 	border-radius: 5px;
	// }
}