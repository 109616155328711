.select-with-icon {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-with-icon select {
  padding-left: 30px;
  cursor: pointer;
}

.select-with-icon .select-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}