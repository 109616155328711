.bl-item
	&.edition-blocked
		background-color: #ff8181 !important
		color: white !important
		.bl-label-numeroserie
			color: #444444 !important
		&.blocked-VENDU
			background-color: #9898f7 !important
		&.blocked-RENVOYE
			background-color: #e198f7 !important
		&.blocked-SAV
			background-color: #e198ff !important
		&.blocked-SAV_OK
			background-color: #F1F8ff !important

	&:nth-child(even)
		background-color: #f3f3f3
		
	padding-top: 6px
	padding-bottom: 6px
	border-radius: 5px
	margin-top: -1px
	//border: 1px solid #e2e2e2
	//margin-bottom: 5px
	.ant-switch 
		height: 46px
		border-radius: 5px
		width: 100%
		&.ant-switch-checked 
			background-color: var(--primary)
	
	.ant-switch-handle 
		border-radius: 5px
		height: 42px
		&::before 
			position: absolute
			top: 0
			right: 0
			bottom: 0
			left: 0
			background-color: #fff
			border-radius: 5px
			-webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2)
			box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2)
			-webkit-transition: all 0.2s ease-in-out
			transition: all 0.2s ease-in-out
			content: ''
		
	
	.ant-switch-inner 
		font-size: 14px
	
.bl-add-product
	.ant-switch 
		height: 32px
		border-radius: 5px
		width: 100%
		&.ant-switch-checked 
			background-color: var(--primary)
	
	.ant-switch-handle 
		border-radius: 5px
		height: 28px
		&::before 
			position: absolute
			top: 0
			right: 0
			bottom: 0
			left: 0
			background-color: #fff
			border-radius: 5px
			-webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2)
			box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2)
			-webkit-transition: all 0.2s ease-in-out
			transition: all 0.2s ease-in-out
			content: ''
		
	
	.ant-switch-inner 
		font-size: 14px
.header-nouveau-bl
	//


.bl-list-supplier-name
	margin: 0px
	padding: 0px
	height: 18px
	opacity: .5
	
.bl-list-product-name
	font-weight: bold
	font-size: 18px
	
.bl-list-size-name
	width: fit-content
	background: #00000015
	border: 1px
	border-radius: 8px
	padding-right: 8px
	padding-left: 5px

.bl-label-numeroserie
	font-size: 12px
	font-style: italic
	position: absolute
	top: 0
	left: 16px

.bl-label-numeroserie-input
	padding-left: 10px !important
	padding-right: 10px !important
	padding-top: 20px !important


.stock-no
	background-color: #ffeeee