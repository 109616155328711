$awui-lefbar-link-unselected-color: "#8492A6";
$awui-lefbar-link-selected-color: "#F9F9F9";
$awui-lefbar-link-selected-bg_color: var(--info);
$awui-lefbar-link-hover-color: var(--info-dark);

$awui-lefbar-link-collapser-color: "#8492A6";
$awui-lefbar-link-collapser-bg_color: "white";

:root {
    --awui-lefbar-link-unselected-color: #{$awui-lefbar-link-unselected-color};
    --awui-lefbar-link-selected-color: #{$awui-lefbar-link-selected-color};
    --awui-lefbar-link-selected-bg_color: #{$awui-lefbar-link-selected-bg_color};
    --awui-lefbar-link-hover-color: #{$awui-lefbar-link-hover-color};
    --awui-lefbar-link-collapser-color: #{$awui-lefbar-link-collapser-color};
    --awui-lefbar-link-collapser-bg_color: #{$awui-lefbar-link-collapser-bg_color};
}

.leftbar {
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: #F7F7F7;
    transition: padding .2s ease-in-out;

    &--collapse {
        padding-inline: .5rem;

        .leftbar__link__label {
            width: 0;
            margin-left: 0;
            overflow: hidden;
        }
    }

    &__separator {
        all: initial;
        height: 1px;
        width: 80%;
        margin-inline: auto;
        margin-block: .5rem;
        border-bottom: solid 1px #dbdbdb;
    }

    &__avatar {
        all: initial;
        padding: .5rem;
        margin-block: .2rem;
        border-radius: 1.5rem;
        color: white;
        display: flex;
        place-items: center;
        user-select: none;
        font-family: 'Nunito';
        font-weight: bold;
        background: linear-gradient(50deg, var(--primary), var(--primary-gradient));;
    }

    &__link {
        all: initial;
        padding: .5rem;
        margin-block: .2rem;
        border-radius: 0.3rem;
        color: var(--awui-lefbar-link-unselected-color);
        display: flex;
        place-items: center;
        user-select: none;
        font-family: 'Nunito';
        font-weight: bold;

        &__label {
            width: 10rem;
            margin-left: .5rem;
            white-space: nowrap;
            transition-property: width, margin-left;
            transition-timing-function: ease-in-out;
            transition-duration: .2s;
        }

        &--collapser {
            color: var(--awui-lefbar-link-collapser-color);
            background-color: var(--awui-lefbar-link-collapser-bg_color);
            border: solid 1px #D9D9D9
        }

        &--active {
            color: var(--awui-lefbar-link-selected-color);
            background-color: var(--awui-lefbar-link-selected-bg_color);
        }

        &:not(.leftbar__link--active):hover {
            color: var(--awui-lefbar-link-hover-color);
        }

    }
}