.type-preview {
	display: flex;
	flex-direction:column;
	background-color: red;
	border-radius: 5px;
	border: 1px solid white;
	height: 94px;
	padding: 4px;
	cursor: pointer;
	.name, .timerange{
		color: white;
		font-size: 0.75rem;
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
		line-height: 1.66;
		letter-spacing: 0.03333em;
	}
	.name {
		font-weight: bold;

	}


}
.hidden-color-picker {
	visibility: hidden;
	width: 1px;
	height: 1px;
}

.input-group-append {
	flex-basis: 36%;
	
	.input-group-text {
		width: 100%;
	}
}

.switch-synchro {
    position: absolute;
    right: 24px;
    font-size: 18px;
}

.ant-switch-small .ant-switch-inner {
	margin: -3px 5px 0 18px;
}

.ant-switch-small.ant-switch-checked .ant-switch-inner {
    margin: -5px 18px 0 5px;
}