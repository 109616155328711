@import "../../assets/scss/variables.scss";

.coverage-demand-list-page {
  h1 {
    font-size: 24px;
  }

  .patient-name {
    background-color: #EFF2F7;
    border-radius: 5px;
    padding: 5px 10px;
    min-width: 200px;
    text-align: center;
  }

  .line {
    border-bottom: 2px solid $primary;
    margin-bottom: $spacing-regular;
    margin-top: $spacing-regular;
  }

  .ant-tag {
    max-width: 400px;
    background-color: transparent;
  }

  .coverage-demands-list-actions {
    display: flex;
    gap: 5px;

    .action-button {
      width: 32px;
      height: 32px;
      padding: 0;

      img {
        margin: auto;
        width: 16px;
        height: 16px;
      }
    }
  }

  .button-common {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    opacity: .8;
    transition: 20ms;
    border-radius: 5px;

    &:hover {
      opacity: 1;
      transition: 20ms;
      @extend .with-shadow;
    }
  }


  .search-doc {
    @extend .button-common;
    border: 1px solid $primary;
  }

  .search {
    @extend .button-common;
    border: 1px solid $warning;
  }

}
