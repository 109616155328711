.modal-document-viewer {
  width: 100%;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
		"edit"
		". delete"
		"sign mail"
		"download command"
		"navigation navigation";
  align-items: flex-end;

  @media (min-width: 992px) {
    grid-template-columns: repeat(auto-fit, minmax(max-content, 20%));
    grid-template-areas:
			"delete edit pec1 pec2 navigation sign mail"
			"navigation . download command";
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(9, minmax(max-content, 10%));
    grid-template-areas: "delete edit pec1 pec2 .  navigation sign mail download command";
  }

  .btn {
    margin: 0;
    padding: 0.5rem;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__nav {
    grid-area: navigation;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 1fr 1fr;

    &__button {
      &--simplified {
        grid-column: 1 / 3;
      }

      &--page-facture {
        grid-column: 1 / 3;
      }

      &--prev {
        grid-column: 1 / 2;
      }

      &--next {
        grid-column: 2 / 3;
      }
    }
  }

  .icon-button {
    width: 39px;
    height: 39px;
  }

  &__button {
    &--sign {
      grid-area: sign;
      height: 39px;
      width: 39px;
    }

    &--mail {
      grid-area: mail;
      height: 39px;
      width: 39px;
    }

    &--delete {
      grid-area: delete;
    }

    &--edit {
      grid-area: edit;
    }

    &--download {
      grid-area: download;
      display: flex;
      column-gap: 22px;
      flex-direction: row;
    }

    &--command {
      grid-area: command;
    }

    &--pec1 {
      grid-area: pec1;
    }

    &--pec2 {
      grid-area: pec2;
    }
  }

  .extension {
    max-width: 50px;
  }
}
