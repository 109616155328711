.documentation-container {
	overflow: hidden;
	min-height: 600px;
	height: 80vh;

	.documentation-data * {
		max-width: 100%;
		padding-right: 0.5rem;
	}
}

.loading-documentation {
	min-height: 50px;
	display: flex;
	justify-content: center;
}

.custom-modal-style {
	width: 80vw;
}

.modal-xl {
	max-width: 80vw;
	max-height: 80vh;
}
