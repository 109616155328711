@import "../../../assets/scss/variables.scss";

.ant-filter-value-tag {
  border-radius: 10px;
  background-color: $blue-dark-navui;
  color: white;
  opacity: 1;

  padding: 6px 13px;

  display: flex;
  flex-direction: row;

  &:hover {
    opacity: .8;
    transition: 100ms ease-in-out;
    cursor: pointer;
  }

  .tag-label {
    font-weight: 800;
  }

  .remove {
    margin-left: $spacing-small;
    text-align: right;
    font-weight: bolder;
  }

  .more {
    color: $blue-dark-navui;
    background: white;
    margin-left: $spacing-small;
    padding: 0 3px;
    border-radius: 5px;
    font-size: 14px;
  }
}
