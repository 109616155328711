
.settings-status {

    .settings-statusLoader {
        margin: 0 auto;
        width: 100%;
    }

    .settings-status-filter {
        display: flex;
        margin-bottom: 20px;
    }

    .settings-status-card-group {
        overflow: hidden;
        transition: max-height 1s ease-in-out;
        .settings-status-card {
            min-width: 24%;
            margin-bottom: 10px;
            flex-grow: 0;
            padding: 0px 10px;
    
            .label-group {
                display: flex;
                .label {
                    margin-left: 11px;
                    margin-right: auto;
                    margin-top: 5px;
                    text-align: left;
                    font-weight: bold;
                    font-size: 16px;
                    width: 80%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    display: inline-flex;
                    input {
                        width: 100%;
                        height: 24px;
                        border: none;
                        padding: 0px;
                        text-align: left;
                    }
        
                }
            }
            .SPSelect {
                margin-left: 0;
                bottom: 0px;
                text-align: left;
                font-size: 16px;
                border-radius: 0px 0px 5px 5px;
                color: #bfbfbf;
                width: 80%;
                .settings-status-card-flex {
                    display: inline-flex;
                }
            }
        }
    }
}