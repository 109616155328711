.ant-popover, .ant-popover-content
    //background-color: red
    // box-shadow: 5px 5px 5px black

.ant-popover-title
    font-weight: bold

.ant-popover-inner
    border-radius: 5px
    //background-color: yellow
    border: 1px solid #ddd
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .12), 0 9px 28px 8px rgba(0, 0, 0, .12)