.dataTable {
	margin-top: 1rem;
	margin-bottom: 1rem !important;
}

.removeantd-class {

	input {
		line-height: calc(1.5em + 1.5rem + 2px) !important;
		vertical-align: middle !important;
	}
	.ant-select-selection-placeholder {
		margin: auto;
	}

	.ant-select-selection-search-input {
		height: 100% !important;
	}

	.ant-select-selector {
		padding: 0px;
		position: absolute !important;
		width: 100% !important;
		height: 100% !important;
		left: 0px !important;
		top: 0px !important;
		box-shadow: inset 0 1px 1px rgba(31, 45, 61, 0.075);
		background-color: transparent !important;
  		border-color: transparent !important;
	}

	&.antd-add-padding {
		.ant-select-selection-item {
			padding: 8px 10px !important;
		}

	}
	// with form-control-sm, the 8px vertical padding makes the content offcenter. Thus we need 5px
	&.antd-add-padding.form-control-sm .ant-select-selection-item  {
		padding: 5px 10px !important;
	}

	&.multiple {
		.ant-select-selection-item-content {
			padding: 0px 8px !important;
		}

		.ant-select-selection-item {
			margin-left: 6px !important;
			color: $white;
			border-radius: 5px;
			background-color: $success;
			font-weight: bold;
		}

		.anticon.anticon-close {
			color: $white !important;
			font-size: 14px !important;
		}
	}
}

.ant-select-item,
.ant-select-item-option,
.ant-select-item-option-active,
.ant-select-item-option-selected {
	padding: 12px !important;
	font-size: 14px;
}

.tablerow[class*=_VENTE_ANNULE] {
	background-image: linear-gradient(140deg, #ede4e4 25%, #e3dada 25%, #e3dada 50%, #ede4e4 50%, #ede4e4 75%, #e3dada 75%, #e3dada 100%);
	background-size: 62.23px 52.22px;
	opacity: .5;
}
