.editor {
	flex: 1;
	height: 100%;
	width: 100%;

	.tox-tbtn[title="save" i],
	.tox-tbtn[title="enregistrer" i] {
		// FIXME: intl if needed
		padding: 0 10px;
		width: auto;

		.tox-icon {
			display: none;
		}

		&::after {
			content: "Enregistrer"; // FIXME: intl if needed
		}
	}

	// remove tiny mce branding
	.tox-statusbar__branding {
		display: none;
	}
	#noteView > div {
		height: 100% !important;
		overflow: hidden !important;
	}
}
