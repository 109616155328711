.partner-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  padding: 20px;
  grid-gap: 40px;
}

.partner-card {
  border-radius: 1.25rem !important;
  width: 90%;

  &_sda {
    border-radius: 1.25rem !important;
    width: 70%;
  }
}

.partner-card-header {
  border-radius: 1.25rem 1.25rem 0 0 !important;

  &_sda {
    margin: 0 10% 0 10%;
    border-radius: 1.25rem 1.25rem 0 0 !important;
    width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: fill-available;
  }

}


.partner-button-pill {
  border-radius: 0.5rem;
  border: transparent;
  display: inline-block;
}

.sda-logo {
  width: 4rem;
  height: 3rem;
}

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 900px,
  lg: 1024px);