// Colors
$white: #fff;
$gray: #8492a6;
$gray-light: #e0e6ed;

$warning: #fc0;
$danger: #ff5630;
$success: #18bc9c;
$info: #18ABBC;

$primary-darker: #149a80;
$primary: #18bc9c;
$primary-lighter: #0ce9bd;
$primary-light: #e4f2ef;

$secondary: #d6ddea;
$secondary-darker: #bababa;
$disabled: #93939C;

$light: #eff2f7;
$dark: #273444;

$text-dark: #3c4858;

$blue: #00b8d9;
$blue-darker: #03869b;
$blue-dark-navui: #0F758E;
$blue-light: #ccf1f7;
$red: #ff5630;
$red-light: #ffddd6;

$spacing-regular: 15px;
$spacing-small: 12px;

$font: "https://fonts.googleapis.com/css?family=Nunito:300,400,600,700" !default;
$font-family-custom-sans-serif: "Nunito", sans-serif !default;
$font-family-base: $font-family-custom-sans-serif !default;


:root {
  --loading-logo: url("../img/brand/logo-chapeau-audiowizard-couleur.png");
  --loading-logo-animated: url("../img/brand/animated-logo.gif");
}

.with-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.20);
}
