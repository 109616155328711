@import "../../assets/scss/variables.scss";

.coverage-demand-badge {
  color: white;
  font-size: 14px;
  font-family: $font-family-custom-sans-serif;
  font-weight: 500;
  text-align: center;
  border-radius: 15px;
  padding: 5px 2px;
  min-width: 90px;
}
