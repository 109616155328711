.leftnavbar-badge {
	position: absolute;
	bottom: 42px;
	right: 12px;
	background-color: $red;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	text-align: center;
	font-size: 12px;
	font-weight: bold;
	line-height: 20px;
	vertical-align: middle;
	color: $white;
}

.nav-application > .btn {
	width: 100px !important;
	height: 100px !important;
}

// STATISTIQUES
.statistiques-row {
	.card-fluid > .card-body {
		overflow: hidden;
	}
}

// SLIM LEFTBAR
// SCROLLBAR STYLE

.card-body::-webkit-scrollbar {
	overflow-x: visible;
	overflow-y: scroll;
	width: 6px !important;
	background: transparent !important; /* make scrollbar transparent */
}

#main-body::-webkit-scrollbar {
	overflow-x: visible;
	overflow-y: scroll;
	width: 6px !important;
	background: transparent !important; /* make scrollbar transparent */
}

::-webkit-scrollbar:horizontal {
	width: 0px;
	height: 0px;
}
::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}
::-webkit-scrollbar-thumb {
	background: #bfbfbf;
	border: 31px none #ffffff;
	border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
	background: #a1a1a1;
	width: 100px !important;
}
::-webkit-scrollbar-thumb:active {
	background: #a3a3a3;
}
::-webkit-scrollbar-track {
	background: transparent;
	border: 1px solid #ffffff;
	border-radius: 5px;
}
::-webkit-scrollbar-track:hover {
	background: #f2f2f2;
}
::-webkit-scrollbar-track:active {
	background: #f5f5f5;
}
::-webkit-scrollbar-corner {
	background: transparent;
}

// SCROLLBAR STYLE FOR FIREFOX
@-moz-document url-prefix() {
	#leftBar-fullScreen {
		scrollbar-width: none;
	}

	#main-body {
		scrollbar-width: none;
	}

	.scrollable {
		scrollbar-width: thin !important;
	}

	* {
		scrollbar-width: thin;
	}
}

// FIN SCROLLBAR STYLE

#leftBar-fullScreen {
	height:  100%;
	overflow: scroll;
	z-index: 1;
	max-width: 5%;
	@media (max-width: 992px) {
		max-width: 6%;
	}
	transition: max-width 0.2s linear;
}

#leftBar-fullScreen::-webkit-scrollbar {
	width: 0px !important;
	background: transparent; /* make scrollbar transparent */
}

#leftBar-body {
	.active {
		display: flex;
		justify-content: flex-end;
	}
}

#main-body {
	overflow-x: visible;
	overflow-y: scroll;
	height: calc(100% - 72px);
	position: absolute;
	max-width: 100% !important;
	width: 95%;
	margin-left: 5%;
	@media (max-width: 992px) {
		width: 94%;
		margin-left: 6%;
	}
	transition: width 0.2s linear;
	transition: margin 0.2s linear;
	transition-delay: 0s, 0.2s;
}

.patientLayout {
	width: 100% !important;
	margin-left: 0% !important;
}

.card-fluid > .card-body {
	overflow-x: visible;
	overflow-y: scroll;
}

.card-fiche-patient > .card-body {
	overflow-x: visible;
	overflow-y: scroll;
}

#leftBar-fullScreen:hover {
	max-width: 150px;
	width: 150px;
	transition: 0.2s linear;
	@media (max-width: 992px) {
		max-width: 130px !important;
		width: 130px !important;
	}
	#leftBar-lock {
		display: flex;
		justify-content: flex-end;
		margin-top: 6%;
		visibility: visible;
	}
	.slim-bar-no-text {
		visibility: visible !important;
	}
	.fa-angle-double-right {
		transform: rotate(180deg);
		transition: 0.2s linear;
		z-index: -1;
	}
	#leftBar-body .active {
		justify-content: center;
	}
}

#leftBar-fullScreen:hover ~ #main-body {
	margin-left: 150px !important;
	width: calc(100% - 150px) !important;
	transition: 0.2s linear;
	@media (max-width: 992px) {
		margin-left: 130px !important;
		width: calc(100% - 130px) !important;
	}
}

.locked ~ #main-body {
	margin-left: 150px !important;
	width: calc(100% - 150px) !important;
	transition: 0.2s linear;
	@media (max-width: 992px) {
		margin-left: 130px !important;
		width: calc(100% - 130px) !important;
	}
}

.leftbar-navgroup-active {
	color: var(--primary) !important;
	transform: scale(1.05);
	.fa-caret-right {
		transform: rotate(90deg);
		transition: 0.1s linear;
	}
}

.fa-caret-right {
	font-size: 1.5em;
}

.fa-x {
	font-size: 1.2em !important;
}

.main-body-leftBar-locked {
	margin-left: 150px !important;
	width: calc(100% - 150px) !important;
}

#leftBar-lock {
	visibility: hidden;
}

.hidden {
	display: none;
}

.locked {
	animation-play-state: paused;
	max-width: 150px !important;
	z-index: 1;
	@media (max-width: 992px) {
		max-width: 130px !important;
		width: 130px !important;
	}
	#leftBar-lock {
		display: flex;
		justify-content: flex-end;
		margin-top: 6%;
		visibility: visible;
	}
	.slim-bar-no-text {
		visibility: visible !important;
	}
	#leftBar-body .active {
		justify-content: center;
	}
}

#produits-nav-group {
	z-index: 2;
}

.slim-bar-no-text {
	// display:none!important;
	visibility: hidden !important;
	height: 30% !important;
}

.nav-in-group {
	display: inline-block !important;
	box-shadow: none !important;
	align-items: center !important;
}

.nav-in-group:hover {
	background: var(--primary) !important;
	transform: scale(1.05);
}

.card {
	margin-bottom: 0 !important;
}

.bar-expander {
	bottom: 3%;
	text-align: center;
}

.fa-angle-double-right {
	font-size: 1.5em !important;
	transition: transform 0.2s linear;
}

@media screen and (orientation: landscape) {
	.bar-expander {
		display: block !important;
	}
}

// Navbar top
.img-white {
	filter: grayscale(300%) contrast(300%) brightness(300%);
	width: 36px !important;
	height: 36px !important;
}

.nav-link {
	@media (max-width: 768px) {
		padding: 0px !important;
		padding-left: 0.275rem !important;
		padding-right: 0.275rem !important;
	}
	@media (max-width: 414px) {
		padding: 0px !important;
		padding-left: 0.05rem !important;
		padding-right: 0.05rem !important;
	}
	.media-pill {
		@media (max-width: 414px) {
			padding: 0px !important;
			width: 60px !important;
			text-overflow: ellipsis !important;
			overflow: hidden !important;
			white-space: nowrap !important;
			border-radius: 5px !important;
		}
	}
}