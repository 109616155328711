.stl-viewer-container {
	position: relative;
	width: 100%;

	.stl-viewer-loading-text {
		color: black;
		font-size: 2rem;
		user-select: none;

		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.stl-viewer-color-input {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);

		width: 44px;
		padding: 4px;
	}
}
