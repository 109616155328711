@media (max-width: 600px) {


    .navbar-responsive {
        flex-direction: column-reverse;
    }

    .searchbar-container {
        flex-direction: column;
    }

    .searchbar-responsive {
        margin-right: 0px !important;
        margin-left: 0px !important;
        margin-top: 8px !important;
    }

    .left-logo {
        display: none;
    }

    .button-row-logo {
        display: none;
    }


}

@media (min-width: 600px) and (max-width: 1080px) {
    .searchbar-container {
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
    }

    .searchbar-responsive {
        min-width: 0px;
        width: 50%;
        margin-left: 20px;
        margin-right: 20px;
    }

    .navbar-responsive {
        flex-direction: column-reverse;
    }

    .left-logo {
        display: none;
    }

    .button-row-logo {
        display: none;
    }



}

@media (min-width: 1080px) and (max-width: 1570px) {
    .button-row {
        width: 100%;
    }

    .searchbar-container {
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
    }

    .searchbar-responsive {
        min-width: 0px;
        width: 50%;
    }

    .left-logo {
        display: none;
    }

    .left-logo-icon {
        display: none;
    }


}

@media (min-width: 1570px) {
    .left-logo-icon {
        display: none;
    }

    .searchbar-responsive {
        min-width: 22rem;
    }

    .button-row-logo {
        display: none;
    }

    .dropdownMenu-button {
        margin: 0px 0.4rem 0px 0.4rem;
    }
}

.topbar-custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    right: 7px;
}