@keyframes show-res
	0%
		transform: scale(0)
	100%
		transform: scale(1)
@keyframes barberpole
	100%
		background-position: 100% 100%

.patientSelector
	position: fixed
	top: 0px
	left: 0px
	width: 100vw
	height: 100%
	background: rgba(0, 0, 0, 0.6)
	z-index: 1400
	animation: fadein .2s ease-in

	.ps-content
		width: 100%
		height: 100%
		background: rgb(255, 255, 255)
		position: relative
		top: 26px
		margin: auto
		border-radius: 5px
		box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px
		transition: all .5s
		animation: scalerot .2s ease-in
		@media only screen and (min-device-width: 1281px)
			width: 1024px

			span.close-btn
				position: absolute
				float: right
				right: 12px
				top: -2px
				text-transform: uppercase
				border-radius: 5px
				padding-left: 5px
				padding-right: 5px
				height: 34px
				vertical-align: middle
				line-height: 34px
				font-size: 16px
				color: white
				margin-top: 12px
				cursor: pointer
				background-color: #ff9999

		.ps-container
			height: 100%
			overflow-x: hidden
			padding: 12px

		.ps-title
			position: relative
			border-radius: 5px 5px 0px 0px
			top: 0px
			left: 0px
			width: 100%
			line-height: 52px
			height: 52px
			vertical-align: middle
			background-color: rgb(240, 240, 240)
			border-bottom: 1px solid #dfdfdf
			border-radius: 5px 5px 0px 0px
			font-size: 20px
			text-align: center
			text-transform: uppercase

		.search
			height: 60px
			overflow: hidden

			input
				width: 100%
				height: 54px
				padding: 6px
				border-radius: 5px
				border: 1px solid #c7c7c7
				font-size: 20px
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1)
				transition: all .2s linear
				&::placeholder
					color: #8f8f8f
				&:disabled
					background: repeating-linear-gradient(45deg,#fff,	#fff 10px, rgb(245, 245, 245) 10px,rgb(245, 245, 245) 20px)
					background-size: 200% 200%
					animation: barberpole 15s linear infinite

			p
				font-size: 24px
				color: black
				border-bottom: 1px solid black

		.results
			height: calc(100% - 140px)

			> .content
				box-sizing: border-box
				display: flex
				width: 100%
				flex-wrap: wrap
				justify-content: center
				height: calc(100% )
				//background-color: rgba(255, 0, 255, .5)
				overflow: scroll

			p:not(.invalid-feedback)
				font-size: 24px
				color: black
				border-bottom: 1px solid black
				height: 48px

			.cs-btn-patient
				position: relative
				margin-left: 12px
				margin-right: 12px
				border: 1px solid white
				margin: 6px
				width: 260px
				height: 78px
				line-height: 72px
				margin-left: 22px
				margin-right: 22px
				margin-bottom: 16px
				color: black
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1)
				border: 1px solid rgba(0, 0, 0, 0.2)
				text-transform: none
				animation: show-res .3s ease-in-out
				animation-fill-mode: backwards
				border-radius: 5px
				//@media only screen and (min-device-width: 1281px)
				//	width: 900px
				transition: all .3s linear

				&.probright
					background-color: rgb(230, 255, 230)
					color: black

				&:active, &:hover
					background-color: rgb(230, 230, 255)

				span.name
					position: absolute
					left: 0
					top: -8px
					font-size: 18px
					overflow: hidden
					text-overflow: ellipsis
					white-space: nowrap
					padding-left: 22px
					padding-right: 22px
					text-align: center
					width: 100%
					height: 100%

				span.age
					position: absolute
					left: 0
					bottom: -18px
					font-size: 14px
					overflow: hidden
					text-transform: lowercase
					text-overflow: ellipsis
					white-space: nowrap
					padding-left: 22px
					padding-right: 22px
					text-align: center
					width: 100%
					height: 100%
