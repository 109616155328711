:root{
	--primary: #18bc9c;
	--info: #18ABBC;
	--warning: #ffab00;
	--danger: #ff5630;
}

.b-dayview {
    --dayview-border-color: #a7a7a7 !important;
    --dayview-background-color: #fff;
    --dayview-half-hour-line-color: #d0d0d0 !important;
}

.b-calendar .b-sidebar-content .custom-resource-filter .b-icon:before {
	font-size: 150%;
}

.custom-resource-filter .b-icon:before {
	content: "\f0c8";
	opacity: 0.6;
}

.custom-resource-filter .b-selected .b-icon:before {
	content: "\f14a";
	opacity: 1;
}

.custom-resource-filter .combo-color-box {
	padding: 0 5px;
	color: #4f5964;
}

.custom-duration .b-hbox > .b-box-center {
	flex: 1 1 auto;
	flex-wrap: wrap;
}

.custom-status-combo {
	display: flex;
	align-items: center;
	gap: 10px;
}

.event-renderer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-right: 0.3em;
	color: white;
}

.event-renderer-data {
	display: flex;
	flex-direction: column;
	gap: 0.1em;
}

.event-renderer-time-patient {
	display: flex;
	flex-direction: row;
	gap: 0.5em;
}

.event-renderer-link {
	display: flex;
	flex-direction: column;
	gap: 0.2em;
}

.b-event-header {
	display: none;
}
.b-event-time {
	display: none;
}

.b-dayview-day-container .b-calendar-cell .b-cal-event-body {
	padding: 0 0 0 0;
	padding-left: 0.3em;
	padding-top: 5px;
	padding-bottom: 5px;
}

.b-cal-event-body {
	background-color: rgba(255, 255, 255, 0.2) !important;
}

#event-renderer-date {
	font-weight: bold;
}

.b-dayview-day-container .b-calendar-cell .b-cal-event-body {
	padding: 1px 0.5em 0 !important;
}

// Création patient PopUp

#addPatientButton {
	align-items: center;
	margin-bottom: 10px;
	flex: 1 1 100%;
	margin-left: 9em;
}
#addPatientButton > label {
	margin-bottom: 0;
}

#addPatient {
	display: flex;
	flex-direction: column;
	max-width: 800px;
	max-height: 100vh;
	overflow-y: scroll;
}

#addPatient > div > div {
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

#notes label {
	flex: 1 0 6em;
}

#addPatient {
	.crt-patient-sub-group {
		display: flex;
		overflow: visible;
		justify-content: center;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 2em;
	}

	.crt-patient-sub-group > div {
		flex: 1 30%;
	}
}

#completeAddress {
	min-width: 80%;
}
#completeAddress {
	width: 100%;
}

@media (max-width: 600px) {
	.crt-patient-sub-group {
		flex-direction: column !important;
		gap: 0px;
	}
	.b-cal-agenda-date {
		flex: 0 0 5em !important;
	}
	.b-agendaview .b-grid-cell {
		font-size: 0.7em;
	}
	#addPatientButton{
		margin-left: 0;
	}
}

.crt-patient-sub-group #birthDate div {
	max-width: 9em;
}

.selected-event {
	border: solid red 2px;
}

.arrived-event {
	border: solid green 2px
}

.b-highlight-day {
	font-size : 80%;
}

.b-phone-view {
	display: none !important;
}

.b-icon-combo-type {
	margin-left: 0.5em
}

.agenda-event-renderer {
	display: flex;
	flex: 1;
}

.b-short-event {
	flex: 1;
}

.b-grid-body-container .b-cal-event-wrap {
	flex: 1;
}

.b-cal-event-body {
	flex: 1;
}

.agenda-event-renderer-label-nom {
	flex: 2 0;
	padding: 0 5px;
}

.agenda-event-renderer-label-type {
	flex: 4 0;
}

.agenda-event-renderer-link {
	text-align: right;
	flex: 1 0;
	padding-right: 20px;
}

.b-flag-phone {
	width: 1.5em;
	margin-left: 0.5em;
}

#addPatient .crt-patient-phone {
	flex-wrap: nowrap;
}

.b-country-phone-picker .b-list-item {
	padding-top: 3px;
	padding-bottom: 3px;
}

.b-country-phone-input input {
	margin-left: -2em !important;
	padding-left: 3em !important;
}

#doctolibButton{
	align-items: center;
	margin-bottom: 10px;
	flex: 1 1 100%;
}

.resource-filter-title {
	padding-top: 0.5em;
	padding-bottom: 0.5em
}

.custom-resource-filter {
	max-height: 20em;
}

.b-myresourcefilter {
	max-height: 20em
}

.custom-resource-filter .b-icon {
	padding-right: 0.4em;
}

// Event Editor //

// TOP BAR //

.b-toolbar {
	background-color: white !important;
}

.topBarActionButton {
	box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15) !important;
	font-size: 1em !important;
	font-weight: bold !important;
	text-transform: capitalize !important;
	border-radius: 5% !important;
	color: var(--secondary) !important;
	background-color: var(--primary) !important;
	border-color: var(--primary) !important;
}

.b-button label {
	font-size: 1em !important;
	font-weight: bold !important;
	text-transform: capitalize !important;
}

#confirmEventButton, #cancelEventButton, #deleteEventButton{
	box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15);
	font-size: 1em;
	border-radius: 5%;
}

#confirmEventButton {
	color: var(--secondary);
	background-color: var(--primary);
	border-color: var(--primary);
}

#cancelEventButton, #cancelButtonDelete{
	border-color: var(--warning) !important;
	color: var(--warning) !important;
	background-color: transparent !important;
	border-style: solid;
	border-width: 1px;
}

#cancelEventButton:hover, #cancelButtonDelete:hover{
	background-color: var(--warning) !important;
	color: #fff !important;
}

#deleteEventButton, #okButtonDelete{
	border-color: var(--danger) !important;
	border-style: solid;
	border-width: 1px;
	color: var(--danger) !important;
	background-color: transparent !important;
}

#deleteEventButton:hover, #okButtonDelete:hover{
	background-color: var(--danger) !important;
	color: #fff !important;
}

#refreshButton > label, #saveViewButton > label {
	text-align: center;
	margin-bottom: 0px !important;
}

.b-button:not(.b-tab) {
	border-radius: 5%;
}

@media screen and (max-width: 600px){
	.event-editor-fields {
		display: flex;
    	flex-direction: column !important;
	}
	.event-editor-fields > label {
		flex: 1 0 1em !important
	}
	.event-editor-fields > div {
		max-width: 100% !important;
		min-width: 100% !important;
	}
}

// Event Tool Tip 
.b-panel-header.b-dock-top {
    border-bottom: 1px solid #037062 !important;
}

.b-popup-header {
    background-color: var(--primary) !important;
    color: var(--info) !important;
}

.b-textfield.b-contains-focus .b-field-inner {
    border-color: #037062 !important;
}

.b-current-time-indicator {
    border-top: 2px solid var(--info) !important;
}

.b-current-time-indicator::before {
	background-color: var(--info) !important;
}

// Day picker
.b-datepicker {
    border-color: var(--primary) !important;
    border-width: 1px;
}

.b-selected-date {
    background-color: var(--info) !important;
    border-color: var(--info) !important;
}

// Day Renderer header 
.custom-day-cell {
	font-size: 1.8em !important;
    border-radius: 50% !important;
    width: 1.8em !important;
    height: 1.8em !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-transition: background-color 0.4s !important;
    transition: background-color 0.4s !important;
    margin: 0.1em 0 0.2em 0 !important;
}

.custom-day-current {
	background-color: #5fa2dd !important;
    color: #fff !important;
}

.b-cal-cell-header-phone {
	font-size: 0.8em !important;
	flex: 1 1 100% !important;
    color: #606060 !important;
    display: flex !important;
    -ms-flex-flow: column nowrap !important;
    flex-flow: column nowrap !important;
	text-align: center !important;
}

.b-schedule-week-phone {
	font-weight: bold;
    font-size: 16px;
    text-transform: lowercase;
    word-wrap: break-word;
}

//Left Bar

.b-chip {
	display: none !important;
}

.b-label {
	text-transform: capitalize !important;
	font-size: 18px !important;
	font-weight: bold !important;
}

.b-textfield input {
	padding: 0.4em !important
}

// statut filter
#statutButtons {
	margin-top: -50px;
	margin-left: 60px;
}

#btn-missed, #btn-cancelled, #btn-done {
	display: flex;
	gap: 5px;
	background-color: transparent !important;
	border: none !important;
	padding: 0 0 0 10px !important;
	opacity: 30% !important;
}

#btn-missed:hover, #btn-cancelled:hover, #btn-done:hover {
	opacity: 100% !important;
}

#btn-missed:not(.b-tab).b-pressed:hover, #btn-cancelled:not(.b-tab).b-pressed:hover, #btn-done:not(.b-tab).b-pressed:hover {
	opacity: 100% !important;
}

#btn-missed:not(.b-tab).b-pressed, #btn-cancelled:not(.b-tab).b-pressed, #btn-done:not(.b-tab).b-pressed {
	opacity: 80% !important;
}

#btn-missed > i, #btn-cancelled > i , #btn-done > i{
	font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
}

// Laboratory filter 
#btn-done:not(.b-tab).b-pressed {
	margin: 0 !important;
}

.b-list-item {
	// attention modifie tous les combos y compris dans la modale event
	padding: 0.5em 0 0.5em 0.1em !important;
}

.b-chipview{
	margin: 0 !important
}

.b-calendar .b-sidebar-content .b-resourcefilter .b-list-item {
    padding: 0.5em 0.5em 0.1em !important;
}

.b-calendarrow .b-cal-cell-header .b-day-name-date {
	font-size: 1em !important;
}

.b-panel-header .b-header-title {
	font-size: 1em !important;
	font-weight: bold !important;
	padding: 0.5em 0 0.5em 0.5em !important;
}
.b-resourceview-content .b-panel-header {
	height: 3.5em !important;
}

.b-panel-header {
	padding: 0em 0.3em 0em 0.3em !important;
	color: white !important;
}

.b-calendarrow .b-cal-cell-header {
	padding: 0.2em 0 0 0.2em;
}

.select-range-list {
	.b-widget, .b-radiogroup-content  {
		background-color: white;
	}
}

.b-teletrans {
	--b-teletrans-color: purple;
	background: var(--b-teletrans-color) !important;
	color: #fff !important;
}

b.popup > .b-button.b-tool, .b-tool {
	color: white;
}

.chevron {
	display: flex;
	flex-flow: column;
	position: absolute;
	top: 0em;
	padding-top: 0.2em;
	padding-bottom: 0.1em;
	right: 0;
	background-color: white;
	height: 100%;
}

.chevron-resource-agenda-down, .chevron-resource-agenda-up {
	height: 1em;
	:hover {
		background-color:  #e6e6e6;
	}
	display: none;
}

.chevron-resource-agenda-down
{
	padding: 0em 0.2em 0.2em 0.8em;
}

.chevron-resource-agenda-up
{
	padding: 0em 0.2em 0.2em 0.8em;
}

.item-resource-filter {
	width: 100%;
	display: block;
}

.item-resource-label {
	width: calc(100% - 5em)
}

.item-resource-filter:hover .chevron-resource-agenda-down,
.item-resource-filter:hover .chevron-resource-agenda-up  {
	display: inline-block;
}

.b-list-item:last-child .item-resource-filter .chevron-resource-agenda-down,
.b-list-item:first-child .item-resource-filter .chevron-resource-agenda-up  {
	display: none;
}

.b-list-item:first-child .item-resource-filter .chevron {
	bottom: 0;
	justify-content: flex-end;
}

.b-dayview-day-detail .b-cal-event-wrap {
    min-height: 20px;
}

.arrow-v [type=range]::-webkit-slider-thumb {
	background-color: var(--primary);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 40%;
	background-image:url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath  style='fill: white' d='M182.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L128 109.3V402.7L86.6 361.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l96 96c12.5 12.5 32.8 12.5 45.3 0l96-96c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 402.7V109.3l41.4 41.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-96-96z'/%3E%3C/svg%3E")
}

.arrow-h [type=range]::-webkit-slider-thumb {
	background-color:var(--primary);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 70%;
	background-image:url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath style='fill: white' d='M406.6 374.6l96-96c12.5-12.5 12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224l-293.5 0 41.4-41.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3l96 96c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288l293.5 0-41.4 41.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z'/%3E%3C/svg%3E")
}