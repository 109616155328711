.filter-container {
	padding-bottom: 14px;
	// border-bottom: 2px solid var(--primary);
	margin-bottom: 14px;
}

.bordered-preview {
	border: 1px solid var(--secondary);
	overflow: auto;
	height: 55%;
}

.serialNumberContainer {
	tr:nth-child(odd) {
		background: #f3f3f3;
	}
	td {
		height: 30px;
		border-bottom: 1px solid #ddd;
	}
}

.table {
	tr th {
		font-size: 14px;
		font-weight: bold;
	}
	td {
		font-size: 14px;
	}
}

.width-50 {
	width: 50%;
	padding: 0px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.custom-stock {
	.ant-switch {
		height: 46px;
		border-radius: 5px;
		width: 100%;

		background-color: var(--secondary);
		&.ant-switch-checked {
			background-color: var(--primary);
			color: white;
			.ant-switch-inner {
				color: white;
			}
			.ant-switch-handle {
				left: calc(100% - 24px - 2px);
			}
		}

		.ant-switch-handle {
			border-radius: 0px;
			height: 42px;
			width: 24px;
		}

		.ant-switch-inner {
			font-size: 14px;
			color: var(--dark);
		}

		&::before {
			border-radius: 5px;
			-webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
			box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			content: "";
		}
	}
}

.form-checkbox-label {
	display: flex;
	height: 24px;

	label {
		margin-left: 6px;
	}

	input[type=checkbox] {
		width: 20px;
		height: 20px;
		border-radius: 5px;
	}
}

.product-status-info {
	font-size: 1rem;
	font-weight: bold;
}

