
$default-color-schedule-type-VISITE: #ff00ff
$default-color-schedule-type-APPAREILLE: #0000ff
$default-color-schedule-type-SUITE_VISITE: #FF0000
$default-color-schedule-type-ESSAI: #FFFF00
$default-color-schedule-type-ADAPTATION: #00FF00
	
*
	--color-schedule-type-visite: $default-color-schedule-type-VISITE
	--color-schedule-type-appareille: $default-color-schedule-type-APPAREILLE
	--color-schedule-type-suite-visite: $default-color-schedule-type-SUITE_VISITE
	--color-schedule-type-essai: $default-color-schedule-type-ESSAI
	--color-schedule-type-adaptation: $default-color-schedule-type-ADAPTATION

@mixin variable($variable, $property, $fallback)
	#{$property}: $fallback !important
	#{$property}: var(--#{$variable}) !important

.schedule-color-type-VISITE
	@include variable(color-schedule-type-visite, background-color, $default-color-schedule-type-VISITE)

.schedule-color-type-APPAREILLE
	@include variable(color-schedule-type-appareille, background-color, $default-color-schedule-type-APPAREILLE)

.schedule-color-type-SUITE_VISITE
	@include variable(color-schedule-type-suite-visite, background-color, $default-color-schedule-type-SUITE_VISITE)

.schedule-color-type-ESSAI
	@include variable(color-schedule-type-essai, background-color, $default-color-schedule-type-ESSAI)

.schedule-color-type-ADAPTATION
	@include variable(color-schedule-type-adaptation, background-color, $default-color-schedule-type-ADAPTATION)

