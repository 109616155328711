@import "../../assets/scss/variables";

.btn-rounded {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.625rem 2.375rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 0.25rem;
    transition: all 0.15s ease-out;
    box-shadow: 0px 0.05rem 0px rgba(255, 255, 255, 0.15);
    // margin-left: 1rem;
    margin-left: 1px;
    margin-right: 1px;

    &.small {
        padding: 0.55rem 1rem !important;
        font-size: .875rem;
        line-height: .875rem;
        font-weight: normal;
    }


    &.primary {
        background: linear-gradient(50deg, var(--primary), var(--primary));
        color: white;

        &:hover:not(:disabled) {
            background: linear-gradient(50deg, var(--primary), var(--primary-gradient));
            color: white;
        }

        &.outlined {
            color: var(--primary);
            background: linear-gradient(50deg, white, white);
            border-color: var(--primary);

            &:hover:not(:disabled) {
                background: linear-gradient(50deg, var(--primary), var(--primary-gradient));
                color: white;
            }
        }

    }

    &.primary-dark {
        background: var(--primary);
        color: white;

        &:hover:not(:disabled) {
            background: #048D72;
            color: white;
        }
    }

    &.secondary {
        background-color: var(--info);
        color: white;

        &:hover:not(:disabled) {
            background: var(--info);
            border-color: transparent;
            color: white;
        }

        &.outlined {
            background-color: transparent;
            color: var(--info);
            border-color: var(--info);

            &:hover:not(:disabled) {
                background: var(--info);
                border-color: transparent;
                color: white;
            }

        }
    }

    &.danger {
        background-color: #FF5630;
        color: white;
        border-color: #FF5630;

        &:hover:not(:disabled) {
            background: #FF5630;
            border-color: transparent;
            color: white;
        }

        &.outlined {
            background-color: transparent;
            color: #FF5630;
            border-color: #FF5630;

            &:hover:not(:disabled) {
                background: #FF5630 !important;
                border-color: transparent;
                color: white;
            }

        }
    }

    &:disabled,
    &.disabled {
        background: #ececec !important;
        color: rgb(183, 183, 183) !important;
        border-color: transparent !important;
        cursor: not-allowed;
    }

}

.close-icon {
    text-align: center;
    vertical-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-dark) !important;
    background-color: transparent;


    &:hover {
        background: var(--light);
        border-radius: 2rem;
        color: var(--text-dark);
    }
}

.close-icon i {
    font-size: 1.30rem;
    line-height: normal;
}

.notes-close-icon {
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    padding: 4px 8px;
}