.dashboard-statistics {
    margin-bottom: 26px;

    .dashboard-statistics-title {
        span {
            font-size: 1.2rem;
        }
    }
}

.dashboard-patient-filter {
    display: flex;
    width: 100%;
    
    .dashboard-patient-filter-element {
        width: 35%;
        min-width: 250px;

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            z-index: inherit;
        }
    }

    .dashboard-patient-filter-element:last-child {
        width: 20%;
    }
}

.dashboard-Icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    .dashboard-Icon-element {
        display: inline-block;
        vertical-align: middle;
    }
}

.dashboard-filter-button-group {
    margin-right: auto;
}

.dashboard-quick-filter {
    font-size: small;
    border-radius: 8px;
    margin: 0 8px;
    padding: 2px 5px;
}

.dashboard-patient-filter-search {
    width: 35%;
    min-width: 250px;

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        z-index: inherit;
    }
}

.dashboard-patient-filter-search:last-child {
    width: 20%;
}

.dashboard-export {
    margin: 0 0px;
    visibility: hidden;
}

.dashboard-popconfirm {
    .ant-popover-buttons {
        text-align: left;
    }
}

.dashboard-actions {

    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-top: auto;
    color: #102477;
    padding: auto;
    margin: auto 8px;
    font-size: 20px;
}

.option-content {
    .ant-select-item-option-content {
        overflow: unset;
        white-space: nowrap;
    }
}

.dashboard-actions-text {
    color: lightgray;
}

.dashboard-actions:hover {
    color: #0f7864;
}

.dashboard-file-status {
    padding: 0.3rem;
    color: white;
    font-weight: bold;
    background-color: orange;
    white-space: pre;
    border-radius: 5px;
    text-align: center;
}

.dashboard-table {
    margin-top: 18px;
    cursor: "default";
    background-color: white;
    z-index: 2;
}

.file-complete {
    background-color: mediumturquoise;
}

.dashboard-table-actions {
    // margin: 0 0.1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    z-index: 999 !important;
    max-width: 150px;

   
}
.dashboard-bloc-notes > * {
    z-index: 99 !important;
}

.dashboard-checked {
    text-align: center;
}


.dashboard-bloc-notes {
    width: 500px;
    margin: 1rem;
    position: absolute;
    bottom: 20px;
    right: 1rem;
    z-index: 99 !important;
    transition: all 1s;

    .dashboard-notes-card {
        border: 1px solid darkturquoise;
        border-radius: 0.8rem;
        position: relative;

        .notes-container {
            margin: 0.5rem 0 0.5rem;
            overflow-y: scroll;
            max-height: 500px;
        }

        button:first-child {
            position: absolute;
            top: 2px;
            right: 0;
        }

        .bloc-notes-footer {
            display: flex;
        }
    }
    
    .dashboard-note {
        text-align: center;
        
        .notes-container-header {
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            span {
                color: lightgray;
                font-weight: bold;
            }

            .user-name {
                color: grey;
                font-weight: bold;
                margin-right: 10px;
            }

            .date-note-center {
                margin-right: auto;
            }
            .date-note-right {
                margin-left: auto;
            }
            
        }
        
        div {
            display: flex;
        }

        p {
            text-align: left;
            border-radius: 0.8rem;
            padding: 0.4rem 1rem;
            font-weight: bold;
            max-width: 80%;
            word-wrap: break-word;
        }

        .self {
            background-color: mediumturquoise;
            margin: 0.5rem 0.5rem 0.5rem auto;
            color: white;
        }
        .other {
            background-color: lightgray;
            margin: 0.5rem auto 0.5rem 0;
            color: black;
        }
    }

    .dashboard-note-input {
        border: none;
        border-bottom: 1px solid gray;
    }
}

.dashboard-message-box {
    width: auto;
    height: max-content;
    border: 1px solid darkturquoise;
    border-radius: 0.8rem;
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 999 !important;
    transition: all 1s;

    .dashboard-message-card {
        border-radius: 0.8rem;
        min-width: 50rem;
        min-height: 25rem;
        max-width: 500px;
        position: relative;
        
        .dashboard-message-box-editor {
            height: max-content;
            min-height: 250px;
        }

        .message-box-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            button {
                height: auto;
                margin: 1rem 1rem 0;
                padding: 0 1rem;
                font-size: 1.2rem;
            }
        }
    }
}

.ant-table-filter-column {
    margin: auto !important;

}

.ant-tag {
    font-weight: bold;
    border: 0px solid !important;

}

.small-cell-text {
    font-size: small;
}


@media(min-width: 850px){
    .dashboard-status-filter {
        width: 100%;
        display: flex;

        .dashboard-status-filter-element {
            width: 50%;
        }

        .dashboard-status-filter-element + .dashboard-status-filter-element {
            margin-left: 1rem;
        }
    }
}

.dashboard-patient-footer-pagination {
    display: flex;
    .dashboard-patient-style-margin {
        margin-left: auto;
    }
}

.dashboard-row {
    height: 60px;
}

.editable-row {
    background-color: white;
}

.editable-cell {
	position: relative;
	padding: 10px 10px;
	overflow-wrap: break-word;
}

.ant-table-tbody > tr > td.ant-table-column-sort {
    background-color: white;
    padding: 8px;
}
tr.warning-table-row > td.ant-table-cell {
    background-color: white;
    padding: 8px;
   }

.comment {
    height: 18px;
    width: 80px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 0 5px 0 5px;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: black;
    font-size: smaller;
}

.dashboard-lab {
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: black;
    max-width: 180px;
}