.modal-loading {

	.modal-content {
		min-height: 220px;
	}

	.modal-loading-body {
		margin: 2px;
	}
	
	.modal-loading-message {
		text-align: center;
		font-weight: bold;
		font-size: 18px;
		color: var(--primary);
	}
	
	.modal-loading-spinner {
		text-align: center;
		font-size: 44px;
		color: var(--primary);
		margin-top: -10px;
	}

	.modal-loading-info {
		bottom: 0px;
		left: 0px;
		position: absolute;
		text-align: center;
		font-weight: bold;
		font-size: 14px;
		padding: 8px;
		width: 100%;
	}

	.submessage {
		display: block;
		font-size: 14px;
		text-align: center;
		font-style: italic;
	}

	.progress-container {
		display: block;
		width: 100%;
		height: 10px;
		border-radius: 5px;
		background-color: var(--secondary-dark);

		> div {
			border-radius: inherit;
			height: inherit;
			width: 0%;
			background-color: var(--primary);
			transition: width 0.2s ease-out;
		}
	}
}
