.custom-antd-dropdown
	max-height: 250px
	overflow-x: scroll
	.custom-antd-dropdown-item
		&:nth-child(odd) 
			background-color: #efefef
		padding: 12px
		border-left: 4px solid blue
		&:hover
			background-color: var(--primary-light)
			color: white
			cursor: pointer

		&.bonlivraison
			position: relative
			.category-name
				position: absolute
				right: 18px
				opacity: .5
			.product-name
				font-size: 18px
			.size-name
				//width: 300px
				width: fit-content
				background: #00000015
				border: 1px
				border-radius: 5px
				padding: 5px