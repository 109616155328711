$max-z-index: 2147483638; // ensure result panel always on top of all 

.spotlight {
    position: unset !important; // give priority to navbar flexible height
    line-height: 1.7;
    min-width: 25rem;


    &__search {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: white;
        border-radius: 2rem;
        padding-block: 0.5rem;
        padding-inline: 1rem;


        &__icon {
            margin-right: 0.5rem;
            width: 1rem;
            height: 1rem;
            color: var(--dark);
        }

        &__input {
            all: initial;
            font-size: 0.8rem;
            font-family: 'Nunito', sans-serif;
            font-weight: 600;
            width: 100%;
            text-overflow: ellipsis;

            &::placeholder {
                color: var(--dark);
                opacity: 1;
            }
        }
    }

    &__result {
        position: absolute;
        z-index: #{$max-z-index};
        background: white;
        top: 100%;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        box-shadow: 0px 2px 100px #0000005c;
        transition: height 300ms;
        min-width: 25rem;
        overflow: hidden;

        &__wrapper {
            padding: 1rem;
        }

        &__group {
            margin-bottom: 0.5rem;

            &__category-name {
                color: var(--primary);
                font-weight: bold;
                margin-bottom: 0.5rem;

            }

            .item {
                display: flex;
                border-radius: 5px;
                color: var(--dark);
                border-bottom: 1px solid rgba(128, 128, 128, 0.158);
                margin-left: 0.2rem;
                padding-inline: 0.5rem;
                padding-block: 0.2rem;

            }

            a.item {
                cursor: pointer;

                &:hover {
                    background-color: rgba(0, 0, 0, .03);
                    color: var(--primary);
                }
            }
        }
    }
}