@import "../../assets/scss/variables.scss";

.panel-section-title {
  color: #444;
  font-family: $font-family-custom-sans-serif;
  font-size: 17.6px;
  font-weight: 700;
  border-bottom: 3px solid $success;
  border-radius: 1.5px;
  margin: 0;
}
