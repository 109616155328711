@import "../../assets/scss/variables";

.coverage-demand-modal {
  .modal-title {
    font-family: $font-family-custom-sans-serif;
    color: $text-dark;
    font-size: 16px;
    margin-left: 6px;
    font-weight: 500;
  }

  .footer-buttons {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: "left right";
  }

  .left-buttons {
    grid-area: left;
  }

  .right-buttons {
    grid-area: right;
    text-align: end;
  }
}
