.col-historique-tax-accounting {
    .calc-tax {
        text-align: right;
        width: 100%;
    }
    input {
        text-align: right;
        
    }
    .ant-input-number-handler-wrap {
        display: none !important;
    }

    .ant-input-number-group-wrapper {
        width: 100%;
    }
}