@import "../../assets/scss/variables";

.coverage-demand-doc-modal {
  .modal-title {
    font-family: $font-family-custom-sans-serif;
    color: $text-dark;
    font-size: 16px;
    margin-left: 6px;
    font-weight: 500;
  }

  .left-buttons {
    flex: 1
  }

  .right-buttons {
    flex: 3;
    text-align: end;
  }

  .actions {
    display: flex;
    gap: 5px;

    .action-button {
      width: 32px;
      height: 32px;
      padding: 0;

      img {
        margin: auto;
        height: 16px;
      }
    }
  }

  .button-common {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    opacity: 0.8;
    transition: 20ms;
    border-radius: 5px;

    &:hover {
      opacity: 1;
      transition: 20ms;
      @extend .with-shadow;
    }
  }


  .download-doc {
    @extend .button-common;
    border: 1px solid $primary;
  }

  .mail-doc {
    @extend .button-common;
    border: 1px solid $info;
  }

  .search-doc {
    @extend .button-common;
    border: 1px solid $warning;
  }

}

