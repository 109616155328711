.service-upgrade-modal {  
  max-width: 500px;
  color: var(--gray-dark);
  line-height: 1;
  font-size: calc(15px + 0.390625vw);
  margin-inline: 1em;

  &__logo {
    margin-block: 1.5em;

    img {
      max-width: 100%;
      margin-inline: auto;
      display: block;
    }
    
    figcaption {
      margin-block: 1.5em;
      font-style: italic;
      font-size: 0.7em;
    }    
  }

  &__description {
    
    display: grid;
    grid-template-columns: auto 1fr;
    place-items: center;
    margin-block: 1.5em;
    column-gap: 1em;
    font-size: 1em;
    font-weight: bold;    

    svg {
      font-size: 1.8em;
    }
    
  }
  
  &__actions {
    display: grid;
    place-items: center;
    row-gap: 0.5em;
    border: 1px solid aliceblue;
    padding-block: 1em;

    h3 {
      font-size: 1.2em;
      font-weight: bold;
    }

    button {
      font-size: 0.9em;
      font-weight: bold;
      padding: 0.3em 1.2em;
      border-radius: 0.5em;
    }
    
    &__btn--primary {
      color: white;
      background: var(--info);      
      border: none;
    }

    &__btn--outlined {
      color:  var(--info);
      background: white;      
      border: 1px solid var(--info);
    }
  }
}