/**
 * Styles for React Event Editor popup
 */
.modal-body .b-label {
    flex: 1 0 7em;
}
.b-doctolib {
    color:#0596DE;
    cursor: pointer;
    padding: 0 1em;
}
.d-doctolib {
    margin-top: 1em;
}
.badge-patient-schedule {
    padding: 1em;
    margin-right: 1em;
}
#schedule-notes > .b-label-notes {
    align-self: flex-start;
    margin-top: 10px;
}
.alert-wrong-laboratory {
    margin-bottom: 1em;
    width: 100%;
    display: inline-flex;
    .ant-alert {
        flex: 1 1 100%;
    }
    
}

.alert-wrong-laboratory::before {
    flex: 1 0 7em;
    content: "";
}

.custom-patient-combo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

.custom-patient-combo-laboratory {
    font-size: smaller;
}

#schedule-notes {
    margin-top: 0.6em
}

.aw-recurrence-combo {
    margin-left: -0.3em;
}

.b-recurrenceeditor-body-wrap {
    .b-button.b-raised.b-pressed {
        background-color: var(--primary);
    }
    .b-button.b-raised.b-pressed:hover {
        background-color: var(--primary-dark);
    }
}

.b-cal-recurrence-icon {
    filter: brightness(0.80);
}


